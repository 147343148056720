import { useState, useEffect, useRef } from 'react';

import { 
    ShoppingBag,
} from 'lucide-react';

import { useAppSelector } from '@/redux/store';


import { ShopCard } from './Cards';

import FireButton from './FormElements/FireButton';
import FireDrawer from './FireDrawer';

export default function FireShoppingBag() {
    const {bagItems}  = useAppSelector((state) => state?.shopBagItems);
    const prevBagItemsRef = useRef(bagItems);

    const [openSheet, setOpenSheet] = useState<boolean>(false);

    const TotalPrice = bagItems.reduce((accumulator, product) => {
        return accumulator + product.price * product.amount;
    }, 0);

    useEffect(() => {
        if (prevBagItemsRef.current !== bagItems && bagItems.length > 0 && location.pathname !== '/cart' ) {
            setOpenSheet(true);
        }

        prevBagItemsRef.current = bagItems;

    }, [bagItems])


    return (
        <>
            <FireDrawer
                buttonContent={<ShoppingBag className='cursor-pointer text-white h-11/12 w-11/12 ' />}
                width='w-[92vw] lg:w-[20vw]'
                backgroundColor='white'
                side='right'
                isOpen={openSheet}
                onOpenChange={setOpenSheet} 
            >
                <div className='relative -top-2 pb-1 w-fit'>
                    <p className='text-[clamp(1rem,1vw,3rem)] text-start text-black font-[600] !m-0'>{bagItems.length > 0 ? `Sepetinizde ${bagItems.length} adet ürün var` : 'Alışveriş sepetim'}</p>   
                </div>
                <div className='border-t border-t-gray-200 '>
                    {bagItems.length > 0 ? ( 
                        <div className="flex h-full flex-col">
                            <div className='mt-[10px] mr-[15px] mb-[10px] ml-[15px]'>
                                <ShopCard bagItems={bagItems} size='normal' isCount={true} isRemove={true} />
                            </div>
                            <div className="border-t border-gray-200">
                                <div className='space-y-4 py-4 mt-[10px] mr-[15px] mb-[10px] ml-[15px]'>
                                    <div className='space-y-3'>
                                        <div className="flex justify-between text-base font-[400] text-black">
                                            <p>Ara toplam</p>
                                            <p>${TotalPrice}</p>
                                        </div>
                                        <div className="flex justify-between text-base font-[600] text-black">
                                            <p>Toplam</p>
                                            <p>${TotalPrice}</p>
                                        </div>
                                    </div>
                                    <div className="flex mt-2 gap-3">
                                        <FireButton 
                                            href="/cart" 
                                            label='Sepete git'
                                            backgroundColor='white'
                                            textStyle='text-black text-base font-medium justify-center'
                                            padding='px-6 py-3'
                                            radius='full'
                                            addStyle='flex border border-gray-200 shadow-sm'
                                        />
                                        <FireButton 
                                            href="/checkout" 
                                            label='Ödeme'
                                            backgroundColor='black'
                                            textStyle='text-white text-base font-medium justify-center'
                                            padding='px-6 py-3'
                                            radius='full'
                                            addStyle='flex border border-gray-200 shadow-sm'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ): (
                        <div className='py-6 mt-[10px] mr-[15px] mb-[10px] ml-[15px]'>
                            <p className='text-[16px] font-[400]'>Alışveriş sepetiniz boş görünüyor.</p>
                        </div>
                    )}
                </div>
            </FireDrawer>
            
        </>
    )
}
